<template>
  <div>
    <p>
      {{ truncatedText }}
      <span v-if="!isExpanded">{{ suffix }}<span v-if="suffix == '...'">
        <button v-if="!isExpanded" @click="toggleExpand">ดูเพิ่มเติม</button>
      </span></span>
     
      <span v-if="isExpanded">{{ remainingText }}</span>
    </p>

    <!-- <button v-if="isExpanded" @click="toggleExpand">ดูน้อยลง</button> -->
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: true,
      },
      truncatedLength: {
        type: Number,
        default: 150,
      },
    },
    data() {
      return {
        isExpanded: false,
      };
    },
    computed: {
      truncatedText() {
        return this.text.substring(0, this.truncatedLength);
      },
      remainingText() {
        return this.text.substring(this.truncatedLength);
      },
      suffix() {
        return this.text.length > this.truncatedLength ? '...' : '';
      },
    },
    methods: {
      toggleExpand() {
        this.isExpanded = !this.isExpanded;
      },
    },
  };
</script>

<style scoped>
  button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    padding: 0;
    font-size: 1em;
    text-decoration: underline;
  }
</style>
