<template>
  <div class="text-center">
    <!-- swiper1 -->
    <swiper ref="swiperTop" class="swiper-gallery gallery-bg" :options="swiperOptions">
      <swiper-slide v-for="(data, index) in swiperData" :key="index">
        <b-img-lazy :src="data" class="swiper-lazy" fluid />
        <div class="swiper-lazy-preloader"></div>
      </swiper-slide>
      <div slot="button-next" class="swiper-button-next swiper-button-white" />
      <div slot="button-prev" class="swiper-button-prev swiper-button-white" />
    </swiper>

    <!-- swiper2 Thumbs -->
    <swiper ref="swiperThumbs" class="swiper gallery-thumbs" :options="swiperOptionThumbs">
      <swiper-slide v-for="(data, index) in swiperData" :key="index">
        <div v-if="swiperData.length != 1">
          <b-img-lazy width="100" :src="data" fluid />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import { BImg } from 'bootstrap-vue';
  import 'swiper/css/swiper.css';

  export default {
    props: {
      images: {
        type: Array,
        required: true,
      },
    },

    components: {
      Swiper,
      SwiperSlide,
      BImg,
    },

    data() {
      return {
        playLoad: true,
        /* eslint-disable global-require */
        swiperData: [],
        swiperOptions: {
          lazy: {
            loadPrevNext: true,
          },

          loopedSlides: 5,
          spaceBetween: 10,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
        swiperOptionThumbs: {
          loopedSlides: 5, // looped slides should be the same
          spaceBetween: 10,
          centeredSlides: true,
          slidesPerView: 4,
          touchRatio: 0.2,
          slideToClickedSlide: true,
        },
      };
    },
    async mounted() {
      this.$nextTick(async () => {
        const swiperTop = await this.$refs.swiperTop.$swiper;
        const swiperThumbs = await this.$refs.swiperThumbs.$swiper;
        swiperTop.controller.control = await swiperThumbs;
        swiperThumbs.controller.control = await swiperTop;
        this.swiperData = this.images;
      });
    },
  };
</script>
<style >
  .gallery-bg {
    background: #22292f !important;
  }
  .swiper-wrapper {
    align-items: center !important;
  }
</style>
